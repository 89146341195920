import React, { Component } from 'react';
import { reduxForm, Field, change } from 'redux-form';
import { View, Image } from 'react-native';
import { connect } from 'react-redux';
import routeActions from '../../RouteActions';
import * as actions from './actions';

//component
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import FormInput from '../../components/FormInput/FormInput';
import FormInputCheckbox from '../../components/FormInputCheckbox/FormInputCheckbox';
import SignUpBase from "../SignUpBase/SignUpBase";
import Text from "./../../components/Text/Text";

//styles
import styles from './styles';
import { formLayout } from '../../generalStyles';
import { withGetScreen } from 'react-native-getscreen';
import defaultOrgAvatar from "../../assets/img/default-org-logo.png";

//utils
import { notification } from "../../helperFunctions";
import { SET_REFERRER_STATUS, SET_API_CALL_STATUS } from '../../constants/actions/referActions';
import Spinner from 'react-native-loading-spinner-overlay';

class PublicReferral extends Component {
    constructor(props) {
        super(props);
        this.nameRef = null;
        this.state = {
            subHeading: 'Please fill the following form to proceed',
            inviteSource: null
        }
    }

    componentDidMount() {
        this.nameRef && this.nameRef.focus();
        this.props.initalizeReferData();
    }

    saveReferralData = (e) => {
        e.preventDefault();
        const { formValues, validateReferrer, toggleSpinner } = this.props;
        if (!formValues.full_name || !formValues.email) {
            notification.ref.show({ message: 'Please fill all mandatory fields', isError: true });
        } else {
            toggleSpinner(false);
            validateReferrer(formValues);
            this.setState({ subHeading: "Send Referral via" });
        }
    }

    submitForm = () => {
        const { sendInvite, formValues, referredJobData, toggleSpinner } = this.props;
        if (!formValues.candidate_name || !formValues.candidate_email) {
            notification.ref.show({ message: 'Please fill all mandatory fields', isError: true });
        } else {
            const data = {
                full_name: formValues.full_name,
                email: formValues.email,
                candidate_email: formValues.candidate_email,
                candidate_name: formValues.candidate_name,
                job_id: referredJobData.job.id,
                organization_id: referredJobData.organization.id
            }
            toggleSpinner(false);
            sendInvite(data);
            this.setState({ inviteSource: 'none', subHeading: "Your invite has been successfully sent!" })
        }
    }

    setInviteSource = (source, subHeading) => {
        this.setState({ inviteSource: source, subHeading: subHeading ? subHeading : this.state.subHeading });
    }

    noReferralData = () => {
        routeActions.base();
    }

    publicReferralForm = () => {
        return (
            <View>
                <View>
                    <View style={formLayout.row}>
                        <Field
                            component={FormInput}
                            name='full_name'
                            placeholder='* Full Name'
                            wrapperStyle={formLayout.fullWidth}
                            inputRef={ref => this.nameRef = ref}
                            returnKeyType="next"
                        />
                    </View>
                    <View style={formLayout.row}>
                        <Field
                            component={FormInput}
                            wrapperStyle={[formLayout.fullWidth]}
                            name='email'
                            placeholder='* Email'
                            keyboardType="email-address"
                            returnKeyType="send"
                        />
                    </View>
                    <View>
                        <Field
                            component={FormInputCheckbox}
                            name='termsConditions'
                            checked={true}
                            onValueChange={() => this.props.setFieldValue('termsConditions', true)}
                            textComponent={() => <Text>I accept to the terms of service</Text>}
                        />
                    </View>
                </View>
                <View style={[formLayout.row, { justifyContent: 'center', zIndex: -9 }]}>
                    <PrimaryButton style={styles.saveNextButton} onPress={this.saveReferralData}>
                        Save
                    </PrimaryButton>
                </View>
            </View>
        );
    }

    referralInviteSource = () => {
        return (
            <View style={styles.shareActionButtons}>
                <PrimaryButton style={styles.shareButton} onPress={() => this.setInviteSource("email", "Please fill in the details")}>Email</PrimaryButton>
                <PrimaryButton style={styles.shareButton} onPress={() => this.setInviteSource("link")}>Link</PrimaryButton>
            </View>
        );
    }

    emailInvite = () => {
        const { referredJobData } = this.props;
        const image = referredJobData && referredJobData.organization.logo_image_thumb ? referredJobData.organization.logo_image_thumb : defaultOrgAvatar;
        const imageJob = referredJobData && referredJobData.job.logo_url.thumb_url ? referredJobData.job.logo_url.thumb_url : defaultOrgAvatar;
        const orgDetail = referredJobData && referredJobData.organization;
        const jobDetail = referredJobData && referredJobData.job;

        return (
            <View style={styles.emailInviteContainer}>
                <View style={formLayout.row}>
                    <Field
                        component={FormInput}
                        name='candidate_name'
                        placeholder='* Candidate Name'
                        wrapperStyle={formLayout.fullWidth}
                        inputRef={ref => this.nameRef = ref}
                        returnKeyType="next"
                    />
                </View>
                <View style={formLayout.row}>
                    <Field
                        component={FormInput}
                        wrapperStyle={[formLayout.fullWidth]}
                        name='candidate_email'
                        placeholder='* Candidate Email'
                        keyboardType="email-address"
                        returnKeyType="send"
                    />
                </View>
                <View style={formLayout.noSpaceRow}>
                    <View style={[styles.imageWrapper, { alignItems: 'center' }]}>
                        <Image style={styles.image} source={image} resizeMode="contain" />
                    </View>
                    <View style={styles.orgDetails}>
                        <Text style={styles.company}>{orgDetail.name}</Text>
                        <Text style={styles.industry}>{orgDetail.industry && orgDetail.industry.name}</Text>
                        <Text style={styles.industry}>{orgDetail.industry && orgDetail.organization_size.name}</Text>
                    </View>
                </View>
                <View style={formLayout.noSpaceRow}>
                    <View style={[styles.imageWrapper, { alignItems: 'center' }]}>
                        <Image style={styles.image} source={imageJob} resizeMode="contain" />
                    </View>
                    <View style={styles.orgDetails}>
                        <Text style={styles.company}>{jobDetail.title}</Text>
                        <Text style={styles.industry}>{jobDetail.location && jobDetail.location.full_address}</Text>
                        <Text style={styles.industry}>{jobDetail.job_experience && jobDetail.job_experience.name} - {jobDetail.work_type }</Text>
                    </View>
                </View>
                <View style={[formLayout.row, { justifyContent: 'center', zIndex: -9 }]}>
                    <PrimaryButton style={styles.saveNextButton} onPress={this.submitForm}>
                        Send
                    </PrimaryButton>
                </View>
            </View>
        );
    }

    render() {
        const mobile = this.props.isMobile();
        const { signUpLoading, referredJobData, referrerExists, requestCompleted } = this.props;
        const mainHeading = 'Welcome to Boon';

        var child = null;
        if (!referredJobData || !referredJobData.job || !referredJobData.organization) {
            child = this.noReferralData();
        } else if (referrerExists === false && this.state.inviteSource === null) {
            child = this.referralInviteSource();
        } else if (referrerExists === false && this.state.inviteSource === 'email') {
            child = this.emailInvite();
        } else if (requestCompleted === true) {
            child = this.publicReferralForm();
        }

        return (
            <SignUpBase mobile={mobile} signUpLoading={signUpLoading} mainHeading={mainHeading} subHeading={this.state.subHeading}>
                {child}
                <Spinner visible={!requestCompleted} />
            </SignUpBase>
        )
    }
}

PublicReferral = reduxForm({ form: 'publicReferralForm' })(withGetScreen(PublicReferral));

const mapStateToProps = state => {
    return {
        formValues: state.form.publicReferralForm && state.form.publicReferralForm.values ? state.form.publicReferralForm.values : {},
        referredJobData: state.refersReducer.referredJobData,
        referrerExists: state.refersReducer.referrerExists,
        requestCompleted: state.refersReducer.requestCompleted
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setFieldValue: (field, value) => {
            dispatch(change('publicReferralForm', field, value));
        },
        validateReferrer: data => {
            dispatch(actions.validateReferrer(data));
        },
        initalizeReferData: () => {
            dispatch({ type: SET_REFERRER_STATUS, payload: null });
        },
        sendInvite: data => {
            dispatch(actions.sendInvite(data));
        },
        toggleSpinner: data => {
            dispatch({ type: SET_API_CALL_STATUS, payload: data })
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicReferral);
