import EStyleSheet from 'react-native-extended-stylesheet';
import { $navyBlue, $green } from '../../generalStyles';
// import backgroundBoxImage from "../../assets/img/backgroundBoxImage.png";
import backgroundBoxImage from '../../assets/img/bg-invite-image.svg';

export default EStyleSheet.create({
  elementWrapperMobile: {
    backgroundColor: $green,
    height: '100%',
    width: '100%',
    flex: 1,
    resizeMode: 'cover',
    overflow: 'auto',
  },
  elementWrapper: {
    backgroundColor: $green,
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  plainWrapperMobile: {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    flex: 1,
    resizeMode: 'cover',
    overflow: 'auto',
  },
  plainWrapper: {
    backgroundColor: 'white',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  headerBar: {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#F2F2F2',
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerText: {
    color: '#9B9B9B',
    fontSize: 14,
    fontWeight: 500,
  },
  orgLogo: {
    height: 30,
    width: 30,
    marginRight: 13,
  },
  companyInitials: {
    backgroundColor: '#F9B74E',
    color: 'white',
    height: 30,
    width: 30,
    borderRadius: 2,
    marginRight: 13,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoWrapper: {
    paddingVertical: 50,
    paddingHorizontal: 60,
  },
  logoWrapperMobile: {
    paddingVertical: 20,
    alignItems: 'center',
  },
  logo: {
    width: 94,
    height: 41,
    resizeMode: 'contain',
  },
  logoMobile: {
    width: 100,
    height: 80,
    resizeMode: 'contain',
  },
  container: {
    margin: 0,
    position: 'absolute',
    top: '30%',
    backgroundColor: 'transparent',
    width: '30%',
    alignSelf: 'center',
  },
  containerMobile: {
    margin: 0,
    position: 'absolute',
    top: '30%',
    backgroundColor: 'transparent',
    width: '80%',
    alignSelf: 'center',
  },
  textContainer: {
    margin: 1,
  },
  body: {
    backgroundColor: '#fff',
    padding: 20,
    maxWidth: '80%',
    width: '60%',
    minWidth: 350,
    borderRadius: 4,
    marginBottom: 20,
  },
  bodyMobile: {
    zIndex: 10,
    backgroundColor: '#fff',
    margin: 25,
    padding: 20,
    width: '25%',
    minWidth: 300,
    borderRadius: 4,
    marginBottom: 20,
  },
  buttonWrapper: {
    textAlign: 'center',
    alignItems: 'center',
    paddingVertical: 18,
    backgroundColor: '#fff',
    width: '100%',
    borderRadius: 5,
  },
  buttonWrapperMobile: {
    textAlign: 'center',
    alignItems: 'center',
    paddingVertical: 18,
    backgroundColor: '#fff',
    width: '100%',
    borderRadius: 5,
  },
  mainText: {
    fontFamily: 'Roboto-Regular',
    backgroundColor: 'transparent',
    color: '#fff',
    fontSize: 55,
    alignSelf: 'flex-start',
    marginBottom: 10,
    fontWeight: 'bold',
    lineHeight: 80,
  },
  secondaryText: {
    fontFamily: 'Roboto-Regular',
    color: '#fff',
    fontSize: 35,
    marginBottom: 20,
  },
  mainTextMobile: {
    fontFamily: 'Roboto-Regular',
    backgroundColor: 'transparent',
    color: '#fff',
    fontSize: 40,
    alignSelf: 'flex-start',
    marginBottom: 10,
    fontWeight: 'bold',
    lineHeight: 50,
  },
  secondaryTextMobile: {
    fontFamily: 'Roboto-Regular',
    color: '#fff',
    fontSize: 15,
    marginBottom: 20,
  },
  formText: {
    fontFamily: 'Roboto-Regular',
    color: $navyBlue,
    fontSize: 14,
  },
  formTextHeader: {
    fontFamily: 'Roboto-Regular',
    color: $navyBlue,
    fontSize: 25,
  },
  inviteText: {
    fontSize: 32,
    fontFamily: 'Roboto-Regular',
    color: 'white',
    marginBottom: 20,
  },

  formTextMobile: {
    fontFamily: 'Roboto-Regular',
    color: $navyBlue,
    fontSize: 10,
  },
  formTextHeaderMobile: {
    fontFamily: 'Roboto-Regular',
    color: $navyBlue,
    fontSize: 15,
  },
  inviteTextMobile: {
    fontSize: 25,
    fontFamily: 'Roboto-Regular',
    color: 'white',
    marginBottom: 20,
  },
  checkBoxText: {
    paddingTop: 3,
    fontFamily: 'Roboto-Regular',
    color: $navyBlue,
    fontSize: 11,
  },
  checkBoxTextMobile: {
    paddingTop: 3,
    fontFamily: 'Roboto-Regular',
    color: $navyBlue,
    fontSize: 9,
  },
  orgImage: {
    height: 55,
    width: 55,
    borderColor: $navyBlue,
    borderWidth: 2,
    borderRadius: 25,
  },
  orgImageMobile: {
    height: 50,
    width: 50,
    borderColor: $navyBlue,
    borderWidth: 2,
    borderRadius: 25,
  },
  acceptText: {
    color: $green,
    backgroundColor: '#fff',
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  acceptTextMobile: {
    color: $green,
    backgroundColor: '#fff',
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  boldText: {
    fontWeight: 'bold',
  },
  actionButtonContainer: {
    marginTop: 20,
  },
  gainAccessText: {
    fontSize: 16,
    color: 'white',
    margin: 2,
  },
  textSecondary: {
    color: '#444444',
  },
  buttonWidthManager: {
    width:'60%',
    marginTop: 20,
  }
});
