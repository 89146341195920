import qs from 'qs';

import { Button } from '@ant-design/react-native';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { isEmpty } from 'lodash-es';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LinkIcon from '@material-ui/icons/Link';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import React, { Component } from 'react';
import { findNodeHandle, Platform, View, Linking } from 'react-native';
import DocumentPicker from 'react-native-document-picker';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import { notification, validateEmail, validateUrl } from '../../../src/helperFunctions';
import BoonSelectField from '../../components/Basic/BoonSelectField';
import BoonTextField from '../../components/Basic/BoonTextField';
import DrawerHeader from '../../components/Drawer/drawerHeader.js';
import { MAX_RESUME_SIZE } from '../../constants/fileUpload.js'
import Cookies from 'js-cookie';

//components
import JobListElement from '../../components/JobListElement/JobListElement';
import Text from '../../components/Text/Text';
import history from '../../RouteHistory';
import { openReferFormDrawer, closeDrawer } from '../../DrawerActions';
import * as drawerActionsConstants from '../../constants/actions/drawerActions';
import * as organizationsActions from '../../globalActions/organizationsActions';

import { urls } from '../../urls.js';
import StrengthenJobForm from './StrengthenJob/StrengthenJobForm.web.js';
import { formLayout } from '../../generalStyles';
//actions
import * as actions from './actions';
import * as browseActions from '../../globalActions/browserActions';
import * as settingsAction from '../Refer/actions'
//styles
import styles from './styles';
import moment from 'moment';

class ApplyJob extends Component {
  constructor(props) {
    super(props);

    this._scrollToInput = this._scrollToInput.bind(this);
    this.scroll = null;
    this.state = {
      email: this.props.currentUser.email ? this.props.currentUser.email : '',
      name:
        this.props.currentUser.full_name && !!this.props.currentUser.full_name.trim()
          ? this.props.currentUser.full_name
          : '',
      first_name:
        this.props.currentUser.first_name && !!this.props.currentUser.first_name.trim()
          ? this.props.currentUser.first_name
          : '',
      last_name:
        this.props.currentUser.last_name && !!this.props.currentUser.last_name.trim()
          ? this.props.currentUser.last_name
          : '',
      file: undefined,
      message: '',
      numberOfLinks: [{ type: 'ln', value: '' }],
      cvName: '',
      cvFile: null,
      acceptTermsCheckBox: false,
      userPDFfiles: [],
      userCvsArray: [],
      afterApply: true,
      isStrengthFormEmpty: true,
      isLicenceFieldEmpty: true,
      showStrengthForm: false,
      checkStrengthSubmit: false,
      diversityQuestion: false,
      fillStrengthForm: false,
      sendUpdateCall: false,
      generalReferral: false,
      newOptions: [],
      strengthQuestions: {
        title: '',
        employer: '',
        location: '',
        skills: [],
        joiningDate: '',
        license_available: ''
      },
    };
    this.myRef = React.createRef();
    this.referId = sessionStorage.getItem('referId');
  }

  componentDidMount() {
    const { setFieldValue, referUsers, userPDFs, userProfileLinks, getUserDetailForRefererProvider, getOrganizationPermissions, initialValues } = this.props;
    setFieldValue('main_referrer_id', null);
    if (localStorage.getItem('subjectEmail')) {
      let email = decodeURIComponent(localStorage.getItem('subjectEmail').replace(/%20/g, '+'));
      this.setState({ email: email });
      this.setState({ generalReferral: true });
    }

    // if (referUsers.length == 1) {
    //     setFieldValue('main_referrer_id', referUsers[0].value)
    // } else if (referUsers.length == 0) {
    //     setFieldValue('main_referrer_id', undefined)
    // }
    if (userPDFs && userPDFs.length !== 0) {
      this.setState({ userPDFfiles: userPDFs });
    }
    if (userProfileLinks && userProfileLinks.length !== 0) {
      this.setState({ numberOfLinks: userProfileLinks });
    }
    if (!!this.referId) {
      getUserDetailForRefererProvider(this.referId);
    }

    if ( !isEmpty(initialValues) && !!initialValues.job_id.organization_id){
      getOrganizationPermissions(initialValues.job_id.organization_id)
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { formValues, onSubmit, updateForm, candidateId, userRefererProvider } = this.props;
    const { strengthQuestions, diversityQuestion, newOptions } = this.state;
    if (this.state.fillStrengthForm !== prevState.fillStrengthForm) {
      const newNumberOfLinks = this.state.numberOfLinks;
      const params = {
        links_json: JSON.stringify(newNumberOfLinks),
        message: formValues.message,
        files: this.state.userCvsArray.filter((e) => e.is_selected).map((e) => e.pdfFIle),
        name: this.state.first_name.concat(' ', this.state.last_name),
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        user_id: formValues.main_referrer_id,
        message: this.state.message,
        pdf_file_ids: this.state.userPDFfiles.filter((e) => e.is_selected).map((e) => e.id),
        current_title: strengthQuestions.title,
        current_employer: strengthQuestions.employer,
        current_location:
          strengthQuestions.location && strengthQuestions.location.full_address
            ? strengthQuestions.location.full_address
            : '',
        current_skills: strengthQuestions.skills.map((skill) => {
          if (skill.id > 0) return skill.id;
        }),
        new_skills: newOptions,
        diversity_hire: diversityQuestion,
        joining_date: strengthQuestions.joiningDate ? moment(strengthQuestions.joiningDate).format('MM/DD/YYYY') : '',
        license_available: strengthQuestions.license_available,
        user_email: this.props.currentUser.email,
        main_referrer_id: formValues.main_referrer_id,
      };
      params.job_id = formValues.job_id.id;
      if (!!this.referId && !isEmpty(userRefererProvider) && !!userRefererProvider.id) {
        params.main_referrer_id = userRefererProvider.id;
      }
      onSubmit(params, formValues.job_id.organization_id, this.handleShowStrengthenForm, this.handleCloseDrawer);
    }
    if (this.state.sendUpdateCall !== prevState.sendUpdateCall && !this.state.checkStrengthSubmit) {
      const params = {
        current_title: strengthQuestions.title,
        current_employer: strengthQuestions.employer,
        current_location: strengthQuestions.location,
        current_skills: strengthQuestions.skills.map((skill) => {
          if (skill.id > 0) return skill.id;
        }),
        new_skills: newOptions,
        diversity_hire: diversityQuestion,
        joining_date: strengthQuestions.joiningDate ? moment(strengthQuestions.joiningDate).format('MM/DD/YYYY') : '',
        license_available: strengthQuestions.license_available,
        questionable_type: 'Candidate',
        questionable_id: candidateId.candidate_id,
        org_id: formValues.job_id.organization_id,
      };
      params.job_id = formValues.job_id.id;
      updateForm(params, this.handleApplicationUpdate);
    }
  }

  _scrollToInput(reactNode) {
    this.scroll.props.scrollToFocusedInput(reactNode);
  }

  handleFileChange = (file) => {
    const { formValues } = this.props;
    formValues.file = file[0];
    this.setState({ file: file[0] });
  };

  handleLinkChange = (index, e) => {
    let value = e.target.value;
    let links = [...this.state.numberOfLinks];
    links[index].value = value;
    this.setState({ numberOfLinks: links });
  };

  removePDF = () => {
    const { formValues } = this.props;
    formValues.file = undefined;
    this.setState({ file: undefined });
    Platform.OS === 'web' ? (document.getElementById('file_upload').value = '') : '';
  };

  handleDropdownLinkChange = (index, e) => {
    let links = [...this.state.numberOfLinks];
    links[index]['type'] = e.target.value;
    this.setState({ numberOfLinks: links });
  };

  handleAddLink = () => {
    let links = [...this.state.numberOfLinks];
    links.push({ type: 'ln', value: '' });
    this.setState({ numberOfLinks: links });
  };

  handleEmail = (e) => {
    this.setState({ email: e.target.value });
    this.props.setFieldValue('email', e.target.value);
  };

  handleFirstName = (e) => {
    this.setState({ first_name: e.target.value });
    this.props.setFieldValue('first_name', e.target.value);
  };
  handleLastName = (e) => {
    this.setState({ last_name: e.target.value });
    this.props.setFieldValue('last_name', e.target.value);
  };
  handleMessage = (e) => {
    this.setState({ message: e.target.value });
  };

  onHandleUser = (e) => {
    this.props.setFieldValue('main_referrer_id', e.target.value);
  };

  handleCheckBoxChange = (checked) => {
    if (checked) {
      return this.setState({ acceptTermsCheckBox: true });
    }
    return this.setState({ acceptTermsCheckBox: false });
  };
  handleUserPDFChecked = (checked, index) => {
    let newPdfArray = [...this.state.userPDFfiles];
    newPdfArray[index]['is_selected'] = checked;
    return this.setState({ userPDFfiles: newPdfArray });
  };
  handleUserCVSChecked = (checked, index) => {
    let newPdfArray = [...this.state.userCvsArray];
    newPdfArray[index]['is_selected'] = checked;
    return this.setState({ userCvsArray: newPdfArray });
  };
  handlehideCvsFromUser = (index) => {
    this.myRef.current.value = '';
    return this.setState({ userCvsArray: [...this.state.userCvsArray].filter((e, i) => i !== index) });
  };
  handleHidePdfFromUser = (pdfId) => {
    this.setState({ userPDFfiles: [...this.state.userPDFfiles].filter((pdf) => pdf.id !== pdfId) });
    this.props.hidePdfFromUser(pdfId);
  };

  onSubmitForm = () => {
    this.setState({ fillStrengthForm: true });
    setTimeout(() => {
      this.setState({ sendUpdateCall: true });
    }, 90000);
  };

  handleApplicationUpdate = () => {
    const searchParams = qs.parse(window.location.search.replace('?', ''));
    const { jobId, direction } = searchParams;
    if (direction === 'my') {
      const { getTrackerDetails } = this.props;
      getTrackerDetails({
        job_id: jobId,
        direction,
      });
    }
  };

  handleUpdate = () => {
    const { updateForm, candidateId, formValues } = this.props;
    const { strengthQuestions, sendUpdateCall, diversityQuestion, showStrengthForm, newOptions } = this.state;
    if (showStrengthForm) {
      const params = {
        current_title: strengthQuestions.title,
        current_employer: strengthQuestions.employer,
        current_location: strengthQuestions.location,
        current_skills: strengthQuestions.skills.map((skill) => {
          if (skill.id > 0) return skill.id;
        }),
        new_skills: newOptions,
        diversity_hire: diversityQuestion,
        joining_date: strengthQuestions.joiningDate ? moment(strengthQuestions.joiningDate).format('MM/DD/YYYY') : '',
        license_available: strengthQuestions.license_available,
        questionable_type: 'Candidate',
        questionable_id: candidateId.candidate_id,
        org_id: formValues.job_id.organization_id,
      };
      params.job_id = formValues.job_id.id;
      updateForm(params, this.handleApplicationUpdate);
      this.setState({ checkStrengthSubmit: true });
    } else {
      this.setState({ checkStrengthSubmit: true });
    }
  };

  mobileUpload = () => {
    const { formValues } = this.props;
    DocumentPicker.pick({
      type: [DocumentPicker.types.pdf],
    }).then((data) => {
      formValues.file = data;
      this.setState({ file: data });
    });
  };
  handleUploadResume = (f) => {
    if (Object.keys(f).length) {
      if (f[0].size > MAX_RESUME_SIZE) {
        notification.ref.show({ message: 'File has to be lower than 4 MB', isError: true });
        return;
      }
      const newCvsArray = [...this.state.userCvsArray, { pdfFIle: f[0], is_selected: true }];
      return this.setState({ userCvsArray: newCvsArray });
    }
  };
  handleDeleteLink = (index) => {
    let linksArray = this.state.numberOfLinks;
    if (index !== 0 && linksArray.length > 1) {
      let links = [...linksArray].filter((item, i) => !(i === index));
      this.setState({ numberOfLinks: links });
    }
  };

  validateLinks = () => {
    let linksArray = this.state.numberOfLinks;
    const validatedArray = linksArray.map((link) => {
      return link.value ? validateUrl(link.value, link.type) : true;
    });
    return validatedArray;
  };

  handleButtonDisable = (formValue, referred) => {
    const {
      numberOfLinks,
      cvFile,
      acceptTermsCheckBox,
      userPDFfiles,
      first_name,
      last_name,
      email,
      userCvsArray,
      message,
    } = this.state;
    const validatedLinksArray = this.validateLinks();

    if (
      !message.trim() ||
      !first_name.trim() ||
      !last_name.trim() ||
      !email ||
      !validateEmail(email) ||
      validatedLinksArray.includes(false)
    ) {
      return true;
    }

    if (userCvsArray.length > 0 && userCvsArray[0].is_selected) {
      return false;
    }
    const checkVariable = numberOfLinks && numberOfLinks.find((e) => e['value'] === '');
    const checkPdfCheckBox = userPDFfiles && userPDFfiles.find((e) => e['is_selected']);

    if (checkVariable && cvFile === null && !checkPdfCheckBox) {
      return true;
    }
    if (referred && !formValue.main_referrer_id) {
      return true;
    }
    if (Object.keys(this.props.currentUser).length === 0 && !acceptTermsCheckBox) {
      return true;
    }

    return false;
  };
  handleSkip = () => {
    if (this.state.showStrengthForm) {
      if (!this.state.sendUpdateCall) {
        this.setState({
          strengthQuestions: {
            title: '',
            employer: '',
            location: '',
            skills: [],
            joiningDate: '',
            license_available: '',
          },
        });
        this.setState({ diversityQuestion: false });
      }
      this.setState({ checkStrengthSubmit: true });
    }
  };
  handleShowStrengthenForm = () => {
    this.setState({ showStrengthForm: true });
    this.handleApplicationUpdate();
  };

  handleStrengthChange = (e, key) => {
    e.persist();
    this.setState({
      strengthQuestions: {
        ...this.state.strengthQuestions,
        [key]: e.target.value,
      },
    });
  };
  handleStrengthLocationChange = (e, key) => {
    this.setState({
      strengthQuestions: {
        ...this.state.strengthQuestions,
        [key]: e,
      },
    });
  };
  setStrengthQuestions = (data) => {
    this.setState({
      strengthQuestions: {
        ...this.state.strengthQuestions,
        ...data,
      },
    });
  };
  handleSetSkills = (e) => {
    this.setState((state) => ({
      strengthQuestions: {
        ...state.strengthQuestions,
        skills: e,
      },
    }));
  };
  setDiversityQuestion = (e) => {
    this.setState({
      diversityQuestion: e,
    });
  };

  handleStrengthFormData = (e) => {
    this.setState({
      isStrengthFormEmpty: e,
    });
  };
  handleLicenceQuestion = (e) => {
    this.setState({
      isLicenceFieldEmpty: e,
    });
  };
  setNewOptions = (e) => {
    this.setState({
      newOptions: e,
    });
  };
  handleCloseDrawer = () => {
    const { onCloseDrawer } = this.props;
    onCloseDrawer();
  };
  render() {
    const {
      formValues,
      referUsers,
      referredJobData,
      initialValues,
      currentUser,
      userPDFs,
      userProfileLinks,
      hidePdfFromUser,
      candidateId,
      onCloseDrawer,
      details,
      openJobReferForm,
      orgDetails,
      userRefererProvider,
      organizationPermissions,
    } = this.props;
    const web = Platform.OS === 'web';
    
    const referred = !organizationPermissions.prioritize_credit_assignment_policy && (referUsers.length > 1 || (referUsers.length === 1 && referUsers[0].value !== currentUser.id));
    initialValues.referred = referred;
    let job = {};
    if (formValues.job_id && formValues.job_id.id) {
      const { ...rest } = formValues.job_id || {};
      const { full_address, city, state, country_name } = formValues.job_id.location ? formValues.job_id.location : {};
      job = { location: { full_address, city, state, country_name }, city, ...rest };
    }
    job.created_at = initialValues.job_id.created_at;
    const referInitialValues = initialValues.job_id.id && {
      job_id: {
        id: initialValues.job_id.id,
        title: initialValues.job_id.title,
      },
      organization_id: {
        id: initialValues.job_id.organization_id,
        name: initialValues.job_id.organization_name,
        logo_image_url: initialValues.job_id.logo_image_url,
      },
    };
    let isUserOrganization = false;
    if (formValues.job_id && currentUser.organizations) {
      isUserOrganization = currentUser.organizations.includes(formValues.job_id.organization_id);
    }
    return formValues.job_id ? (
      <KeyboardAwareScrollView
        style={[
          { flex: 1, paddingLeft: 16, paddingRight:16, paddingBottom:16, backgroundColor: '#f9f9f9' },
          !web && { marginTop: 30, backgroundColor: '#f9f9f9' },
        ]}
        keyboardShouldPersistTaps="always"
        enableAutomaticScroll
        extraScrollHeight={20}
        innerRef={(ref) => {
          this.scroll = ref;
        }}
      >
        <DrawerHeader
          title={`Apply to ${formValues.job_id.organization_name}`}
          handleCloseDrawer={this.handleCloseDrawer}
        />
        <div
          style={{
            marginTop: 8,
            marginBottom: 8,
            border: '0.5px solid #CCCCCC',
            borderRadius: 5,
            backgroundColor: 'white',
          }}
        >
          <JobListElement job={job} showAllInfo={false} page="refer" />
        </div>
        <View style={this.state.showStrengthForm ? styles.fromWrapperStrength : styles.fromWrapper}>
          {this.state.showStrengthForm ? (
            <StrengthenJobForm
              handleStrengthChange={this.handleStrengthChange}
              handleStrengthLocationChange={this.handleStrengthLocationChange}
              handleSetSkills={this.handleSetSkills}
              appliedByName={this.state.first_name.concat(' ', this.state.last_name)}
              appliedByEmail={this.state.email}
              message={this.state.message}
              formValues={formValues}
              referUsers={referUsers}
              strengthQuestions={this.state.strengthQuestions}
              checkStrengthSubmit={this.state.checkStrengthSubmit}
              orgId={job.organization_id}
              diversityQuestion={this.state.diversityQuestion}
              setDiversityQuestion={this.setDiversityQuestion}
              numberOfLinks={this.state.numberOfLinks}
              userCvsArray={this.state.userCvsArray}
              userPDFfiles={this.state.userPDFfiles}
              newOptions={this.state.newOptions}
              setNewOptions={this.setNewOptions}
              handleStrengthFormData={this.handleStrengthFormData}
              handleLicenceQuestion={this.handleLicenceQuestion}
              setStrengthQuestions={this.setStrengthQuestions}
            />
          ) : (
            <>
              <View style={[formLayout.row, styles.messageRow]}>
                <BoonTextField
                  name="message"
                  label="Message to Employer*"
                  variant="outlined"
                  onChange={this.handleMessage}
                  style={{ marginBottom: 20 }}
                  value={this.state.message}
                  fullWidth
                  multiline
                  minRows={3}
                  maxLength={280}
                  onFocus={(event) => {
                    this._scrollToInput(findNodeHandle(event.target));
                  }}
                />
              </View>
              <div className='apply-job-inputs'>
              <BoonTextField
                label="First Name*"
                variant="outlined"
                fullWidth
                onChange={this.handleFirstName}
                disabled={!!this.props.currentUser.first_name && !!this.props.currentUser.first_name.trim()}
                value={this.state.first_name}
                style={{ marginBottom: 20, height: 40, borderRadius: 6 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ color: 'rgb(15, 188, 113)' }}>
                      <PersonOutlineIcon style={this.state.first_name ? {} : { color: '#bfbfbf' }} />
                    </InputAdornment>
                  ),
                  style: { height: 40}
                }}
                onBlur={() =>
                  this.setState({
                    first_name: this.state.first_name.trim(),
                  })
                }
              />
              <BoonTextField
                label="Last Name*"
                variant="outlined"
                fullWidth
                onChange={this.handleLastName}
                disabled={!!this.props.currentUser.last_name && !!this.props.currentUser.last_name.trim()}
                value={this.state.last_name}
                style={{ marginBottom: 20, height: 40, borderRadius: 6 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ color: 'rgb(15, 188, 113)' }}>
                      <PersonOutlineIcon style={this.state.last_name ? {} : { color: '#bfbfbf' }} />
                    </InputAdornment>
                  ),
                  style: { height: 40}
                }}
                onBlur={() =>
                  this.setState({
                    last_name: this.state.last_name.trim(),
                  })
                }
              />

              <BoonTextField
                label="Confirm Email*"
                variant="outlined"
                fullWidth
                onChange={this.handleEmail}
                value={this.state.email}
                disabled={currentUser.email || this.state.generalReferral ? true : false}
                style={{ marginBottom: 20, borderRadius: 6 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ color: 'rgb(15, 188, 113)' }}>
                      <MailOutlineIcon style={validateEmail(this.state.email) ? {} : { color: '#bfbfbf' }} />
                    </InputAdornment>
                  ),
                  style: { height: 40}
                }}
              />
              </div>
              {!organizationPermissions.prioritize_credit_assignment_policy && (
              (userRefererProvider && userRefererProvider.id !==  currentUser.id) ? (
                <React.Fragment>
                  <Text style={{ color: '#aaaaaa', marginBottom: 20 }}>Referral Provider</Text>
                  <BoonSelectField
                    IconComponent={() => <KeyboardArrowDownIcon color="white" style={{ width: 20 }} />}
                    value={userRefererProvider.full_name}
                    disabled={true}
                  >
                    <MenuItem value={userRefererProvider.full_name}>{userRefererProvider.full_name}</MenuItem>
                  </BoonSelectField>
                </React.Fragment>
              ) : (
                (referUsers.length > 1 || (referUsers.length === 1 && referUsers[0].value !== currentUser.id)) && (
                  <React.Fragment>
                    <Text style={{ color: '#aaaaaa', marginBottom: 20 }}>Referral Provider</Text>
                    <BoonSelectField
                      id="referral-provider-select"
                      label="Referral Provider"
                      IconComponent={() => <KeyboardArrowDownIcon color="white" style={{ width: 20 }} />}
                      onChange={this.onHandleUser}
                    >
                      {referUsers.map(
                        (refer) =>
                          refer.email !== currentUser.email && <MenuItem value={refer.value}>{refer.label}</MenuItem>
                      )}
                    </BoonSelectField>
                  </React.Fragment>
                )
              ))}

              <View style={{ height: 1, backgroundColor: '#e8e8e8', marginBottom: 20, marginTop: 20 }}></View>
              <Text style={{ fontWeight: 'bold', fontSize: 14 }}>
                <Text style={{ color: 'rgb(15, 188, 113)' }}>Only One</Text> of the fields below is{' '}
                <Text style={{ fontStyle: 'italic' }}>required</Text>.
              </Text>
              <View style={{ display: 'flex', flexDirection: 'row', gap: '30px', marginTop: '15px' }}>
                <input
                  type="file"
                  accept=".doc, .docx, .pdf"
                  onChange={(e) => this.handleUploadResume(e.target.files)}
                  id="file_upload"
                  ref={this.myRef}
                  style={{ display: 'none' }}
                />
                <View style={{ paddingTop: '10px' }}>
                  <Text>Attach Resume</Text>
                  <Text style={{ color: '#aaa' }}>(PDF or Word Document)</Text>
                </View>
                <Button
                  onClick={(e) => this.myRef.current.click()}
                  style={{
                    border: 1,
                    color: '#D1D8DC',
                    borderStyle: 'dashed',
                    background: '#F5F7F9',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <Text style={{ color: '#0FBC71', fontSize: '12px' }}>
                    <CloudUploadIcon style={{ fontSize: '12px', padding: '0px 5px 0px 0px' }} />
                    Upload Resume
                  </Text>
                </Button>
              </View>
              {(this.state.userCvsArray.length > 0 || this.state.userPDFfiles.length > 0) && (
                <View style={{ paddingTop: '20px' }}>
                  {this.state.userCvsArray.length > 0 &&
                    this.state.userCvsArray.map((item, index) => {
                      return (
                        <View
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            borderBottomWidth: '1px',
                            borderBottomColor: 'rgba(0, 0, 0, 0.1)',
                            paddingTop: '10px',
                          }}
                        >
                          <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <Checkbox
                              style={{ paddingTop: 0, color: 'green', backgroundColor: 'white' }}
                              color="secondary"
                              name="PdfcheckedBox"
                              id="PdfcheckedBox"
                              checked={item.is_selected}
                              onChange={(e) => this.handleUserCVSChecked(e.target.checked, index)}
                            />
                            <DescriptionOutlinedIcon
                              style={{ color: '#757575', paddingRight: '10px', fontSize: '27px' }}
                            />
                            <Text style={{ paddingTop: '3px', color: '#444444' }}>
                              {item.pdfFIle.name ? item.pdfFIle.name.substring(0, 25) : ''}
                            </Text>
                          </View>
                          <DeleteOutlineOutlinedIcon
                            onClick={() => this.handlehideCvsFromUser(index)}
                            style={{ color: '#757575', paddingLeft: 'auto', cursor: 'pointer' }}
                          />
                        </View>
                      );
                    })}
                  {this.state.userPDFfiles.length > 0 &&
                    this.state.userPDFfiles.map((item, index) => {
                      return (
                        <View
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            borderBottomWidth: '1px',
                            borderBottomColor: 'rgba(0, 0, 0, 0.1)',
                            paddingTop: '10px',
                          }}
                        >
                          <View style={styles.formBlock}>
                            <Checkbox
                              style={{ paddingTop: 0, color: 'green', backgroundColor: 'white' }}
                              color="secondary"
                              name="PdfcheckedBox"
                              id="PdfcheckedBox"
                              checked={item.is_selected}
                              onChange={(e) => this.handleUserPDFChecked(e.target.checked, index)}
                            />
                            <DescriptionOutlinedIcon style={{ color: '#757575', paddingRight: '10px' }} />
                            <Text style={{ paddingTop: '6px', color: '#444444' }}>
                              {item.pdf ? item.pdf.substring(0, 25) : ''}
                            </Text>
                          </View>
                          <DeleteOutlineOutlinedIcon
                            onClick={() => this.handleHidePdfFromUser(item.id)}
                            style={{ color: '#757575', paddingLeft: 'auto', cursor: 'pointer' }}
                          />
                        </View>
                      );
                    })}
                </View>
              )}
              <View style={{ marginTop: '10px' }}>
                {this.state.numberOfLinks.length &&
                  this.state.numberOfLinks.map((link, index) => {
                    return (
                      <div name={`link-${index}`} className="links-div apply-job-inputs">
                        <View style={{ width: '30%', marginBottom: '10px' }}>
                          <Select
                            style={{ color: '#0FBC71', width: link.type === 'pw' ? '160px' : '100px' }}
                            value={link.type}
                            disableUnderline
                            IconComponent={(props) => <KeyboardArrowDownIcon {...props} style={{ color: '#0FBC71' }} />}
                            onChange={(e) => this.handleDropdownLinkChange(index, e)}
                            MenuProps={{
                              style: { marginTop: '40px' },
                            }}
                          >
                            {[
                              { value: 'ln', name: 'Linkedin' },
                              { value: 're', name: 'Resume' },
                              { value: 'po', name: 'Portfolio' },
                              { value: 'pw', name: 'Personal Website' },
                            ].map((menuItem) => (
                              <MenuItem key={menuItem.name} value={menuItem.value}>
                                {menuItem.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </View>
                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                          <BoonTextField
                            label={link.value ? 'URL' : 'Type or paste url'}
                            variant="outlined"
                            fullWidth
                            style={index < 1 ? { width: '100%', height: 40 } : { width: '85%', height: 40 }}
                            onChange={(e) => this.handleLinkChange(index, e)}
                            error={link.value && !validateUrl(link.value, this.state.numberOfLinks[index].type)}
                            value={link.value}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ color: 'rgb(15, 188, 113)' }}>
                                  <LinkIcon />
                                </InputAdornment>
                              ),
                              style: { height: 40}
                            }}
                          />
                          {index > 0 && (
                            <IconButton>
                              <CancelIcon
                                style={{ color: '#CCCCCC' }}
                                onClick={(e) => this.handleDeleteLink(index, e)}
                              />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    );
                  })}
                <div className="link-button-main">
                  <Button style={styles.linkBtn} onPress={this.handleAddLink}>
                    <Text style={{ color: '#aaa', fontSize: 14 }}>Add link</Text>
                  </Button>
                </div>
              </View>

              {Object.keys(currentUser).length === 0 && (
                <View style={{ display: 'grid', gridTemplateColumns: '10% 90%', marginTop: 25 }}>
                  <Checkbox
                    style={
                      this.state.acceptTermsCheckBox
                        ? { paddingTop: 0, color: 'green', backgroundColor: 'white' }
                        : { paddingTop: 0, backgroundColor: 'white' }
                    }
                    color="secondary"
                    name="checkedBox"
                    id="checkedBox"
                    onChange={(e) => this.handleCheckBoxChange(e.target.checked)}
                  />

                  <Text style={{ paddingTop: 3 }}>
                    {' '}
                    I have read and accept the{' '}
                    <a style={{ color: 'black', fontWeight: 'bold' }} href="https://goboon.co/terms" target="_blank">
                      Terms of Service
                    </a>
                    <b> & </b>
                    <a href="https://goboon.co/privacy" target="_blank" style={{ color: 'black', fontWeight: 'bold' }}>
                      Privacy Policy.
                    </a>
                  </Text>
                </View>
              )}
            </>
          )}
          {this.state.showStrengthForm ? (
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              {this.state.checkStrengthSubmit ? (
                <Button
                  onPress={() => {
                    openJobReferForm(referInitialValues);
                  }}
                  style={isUserOrganization ? styles.referButton : styles.disableButton}
                  disabled={!isUserOrganization}
                >
                  <Text style={{ color: isUserOrganization ? '#0FBC71' : '', fontSize: 14 }}>Send a referral</Text>
                </Button>
              ) : (
                <Button onPress={() => this.handleSkip()} style={styles.skipButton}>
                  <Text style={{ color: '#0FBC71', fontSize: 14 }}>Skip</Text>
                </Button>
              )}
              {this.state.checkStrengthSubmit ? (
                <Button
                  onPress={() => {
                    onCloseDrawer();
                    history.push(urls.tracker);
                  }}
                  style={styles.saveButton}
                >
                  <Text style={{ color: '#ffffff', fontSize: 14 }}>View in Tracker</Text>
                </Button>
              ) : (
                <Button
                onPress={() => this.handleUpdate()}
                style={
                  formValues.job_id.organization_name === "Connect Pediatrics"
                    ? (this.state.isLicenceFieldEmpty ? styles.disableButton : styles.saveButton)
                    : (this.state.isStrengthFormEmpty ? styles.disableButton : styles.saveButton)
                }
                disabled={
                  formValues.job_id.organization_name === "Connect Pediatrics"
                    ? this.state.isLicenceFieldEmpty
                    : this.state.isStrengthFormEmpty
                }
              >
              <Text style={{
                color: (formValues.job_id.organization_name === "Connect Pediatrics"
                  ? (this.state.isLicenceFieldEmpty ? '#959595' : '#ffffff')
                  : (this.state.isStrengthFormEmpty ? '#959595' : '#ffffff')),
                fontSize: 14
              }}>
                Save
              </Text>

              </Button>
              )}
            </View>
          ) : (
            <Button
              disabled={this.handleButtonDisable(formValues, referred)}
              onPress={this.onSubmitForm}
              style={this.handleButtonDisable(formValues, referred) ? styles.applyDisable : styles.applyButton}
            >
              <Text style={this.handleButtonDisable(formValues, referred) ? styles.applyTextDisable : styles.applyText}>
                Apply
              </Text>
            </Button>
          )}
        </View>
      </KeyboardAwareScrollView>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form.applyJobForm && state.form.applyJobForm.values ? state.form.applyJobForm.values : {},
    referredJobData: state.refersReducer.referredJobData,
    referUsers: state.refersReducer.referUsers,
    userPDFs: state.refersReducer.userPDFs,
    userProfileLinks: state.refersReducer.userProfileLinks,
    userRefererProvider: state.refersReducer.userRefererProvider,
    currentUser: state.authReducer.currentUser,
    candidateId: state.trackerReducer.candidateId,
    details: state.browseReducer.jobDetails,
    orgDetails: state.organizationsReducer.organizationDetails,
    organizationPermissions: state.settingsReducer.defaultValues,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSubmit: (params, orgId, successCallback, failureCallback) => {
      dispatch(actions.applyJob(orgId, params, successCallback, failureCallback));
    },
    updateForm: (params, successCallback) => {
      dispatch(actions.updateApplyJob(params, successCallback));
    },
    setFieldValue: (field, value) => {
      dispatch(change('applyJobForm', field, value));
    },
    hidePdfFromUser: (pdfId) => {
      dispatch(actions.hidePdfFromUser(pdfId));
    },
    onCloseDrawer: () => {
      dispatch({ type: drawerActionsConstants.CLOSE_DRAWER });
      dispatch(closeDrawer);
    },

    openJobReferForm: (params) => {
      dispatch(openReferFormDrawer(params));
    },
    getUserDetailForRefererProvider: (id) => {
      dispatch(organizationsActions.getUserDetailsForRefererProvider(id));
    },
    getTrackerDetails: (params) => {
      dispatch(browseActions.getTrackerDetails(params));
    },
    getOrganizationPermissions: (orgId) => {
      dispatch(settingsAction.getDefaultSettings(orgId));
    },
  };
};

ApplyJob = reduxForm({ form: 'applyJobForm', enableReinitialize: true })(ApplyJob);

export default connect(mapStateToProps, mapDispatchToProps)(ApplyJob);
