import React, { useMemo } from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import Actions from '../../RouteActions';

//styles
import styles from './styles';
import { jobListElement, common as commonStyles } from '../../generalStyles';
import DeclineButton from './DeclineButton';

//components
import Text from '../../components/Text/Text';
import ListElementButton from '../../components/ListElementButton/ListElementButton';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

//utils
import { CURRENCIES } from '../../constants/currenciesConstants';
import { locationObjToStr, getDateTimeFromNow, isDateValid, notification } from '../../helperFunctions';

//images
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import defaultOrgAvatar from '../../assets/img/default_org_avatar.svg';
import winnerCupSvg from '../../assets/svg/winner-cup.svg';
import RouteHistory from '../../RouteHistory';
import { urls } from '../../urls';

export default function JobListElement(props) {
  const {
    job,
    onPress,
    currentJobId,
    handleOnPress,
    showViewJob,
    direction,
    resendReferral,
    listItem,
    lineStyles,
    page,
    tracker,
    showAllInfo = true,
    firstReferral,
    handleApplyPress,
    from,
  } = props;
  const image = job.logo_image_url ? { uri: job.logo_image_url } : defaultOrgAvatar;
  const trackerStyle = tracker ? jobListElement.opportunityWrapperBackground : jobListElement.elementWrapperBackground;
  let style = [jobListElement.listItem, page === 'refer' ? lineStyles : trackerStyle];
  let activeClassName = '';
  if (listItem) {
    const activeJob = currentJobId === job.uuid;
    style = activeJob ? [jobListElement.listItem, jobListElement.activeListElement] : jobListElement.listItem;
    if (activeJob) {
      activeClassName = 'job-list-active';
      setTimeout(() => {
        const activeListItem = document.querySelector('.job-list-active');
        if (activeListItem && activeListItem.scrollIntoViewIfNeeded) {
          activeListItem.scrollIntoViewIfNeeded();
        }
      }, 500);
    }
  }

  let diffStr = '';
  if (isDateValid(job.created_at)) {
    diffStr = getDateTimeFromNow(job.created_at);
  }

  let locationToDisplay = locationObjToStr(job.location);

  const navigateToEmployerProfile = (job) => {
    Actions.goEmployerProfile({
      employerProfileLink: job.employer_profile_link,
    });
  };

  const ComponentToRender = direction ? View : TouchableOpacity;

  const renderRewards = useMemo(() => {
    if (!job || !job.reward_templates) return null;

    const { currency_id = 'USD', experiential_reward = '', monetary_reward = 0 } = job.reward_templates;
    if ((experiential_reward.length || monetary_reward) > 0) {
      return (
        <>
          <View style={jobListElement.hr}></View>
          <View style={jobListElement.referralRewardsWrapper}>
            <View>
              <Image style={jobListElement.winnerIcon} source={winnerCupSvg} resizeMode="contain" />
            </View>
            {monetary_reward > 0 && (
              <View style={[jobListElement.referralReward, jobListElement.referralRewardPrice]}>
                <Text style={jobListElement.referralRewardText}>
                  {CURRENCIES[currency_id]}
                  {monetary_reward}
                </Text>
              </View>
            )}
            {experiential_reward.length > 0 && (
              <View style={commonStyles.flex1}>
                <View style={jobListElement.referralReward}>
                  <Text style={[jobListElement.referralRewardText, commonStyles.textEllipsis]}>
                    {experiential_reward}
                  </Text>
                </View>
              </View>
            )}
          </View>
        </>
      );
    }
    return null;
  }, [showAllInfo, job.reward_templates]);

  const handleViewJob = () => {
    if (job.category === 'general') {
      if (job.general_ref_application) {
        notification.ref.show({ message: 'You have already applied through this referral.', isError: true });
      } else {
        localStorage.setItem('fromTracker', job.organization_uuid);
        RouteHistory.push(urls.browseAll);
      }
    } else {
      Actions.browseJobDetail({ id: job.uuid });
    }
  };

  return (
    <ComponentToRender style={[style, { marginBottom: 10 }]} className={activeClassName} onPress={onPress}>
      <View style={jobListElement.listItemBody}>
        <TouchableOpacity style={jobListElement.imageWrapper} onPress={() => navigateToEmployerProfile(job)}>
          <Image
            style={{
              width: 52,
              height: 52,
              borderRadius: 5,
              marginRight: 10,
              overflow: 'hidden',
            }}
            source={image}
            resizeMode="contain"
          />
        </TouchableOpacity>
        <View style={styles.flex}>
          <View style={[jobListElement.jobInfoWrapper]}>
            <Text style={[jobListElement.title, styles.jobTitle]} numberOfLines={1}>
              {job.title}
            </Text>
            <TouchableOpacity
              style={commonStyles.linkWrapper}
              onPress={() => {
                Actions.goEmployerProfile({
                  employerProfileLink: job.employer_profile_link,
                });
              }}
            >
              <Text style={styles.organizationName}>{job.company_name || job.organization_name}</Text>
              
            </TouchableOpacity>
            <View style={{ ...commonStyles.linkWrapper, display: 'flex', flexDirection: 'row', maxWidth: '100%' }}>
              <Text
                style={[
                  jobListElement.description,
                  {
                    fontSize: 12,
                    color: '#959595',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: from === 'browse' ? 200 : '100%',
                    display: 'inline-block',
                  },
                ]}
                numberOfLines={1}
              >
                {locationToDisplay}
              </Text>
              <Text style={[jobListElement.description, { fontSize: 12 }]} numberOfLines={1}>
                {Boolean(locationToDisplay) && showAllInfo && ' • '}
                {showAllInfo && diffStr}
              </Text>
            </View>
            {Boolean(job.is_salary_range_public && job.min_salary && job.max_salary)&& (
              <Text
                style={[
                  jobListElement.description,
                  {
                    fontSize: 12,
                    color: '#959595',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: from === 'browse' ? 200 : '100%',
                    display: 'inline-block',
                  },
                ]}
                numberOfLines={1}
              >
                <Text>
                  {job.currency}
                  <Text>{job.min_salary}</Text>
                </Text>
                {' \u2013 '}
                <Text>
                  {job.currency}
                   <Text>{job.max_salary}</Text>
                </Text>
              </Text>
              )}
          </View>

          <View style={styles.buttonDiv}>
            {direction === 'my' && (
              <View style={styles.mainDiv} key="buttons">
                <View>
                  {showViewJob && (
                    <ListElementButton onPress={handleViewJob} style={styles.viewJobButton}>
                      {job.category === 'general' ? 'Apply for opening' : 'View Job'}
                    </ListElementButton>
                  )}
                </View>
                {(firstReferral &&
                  (firstReferral.candidate_status === 'new' ||
                    firstReferral.candidate_status === 'expired' ||
                    firstReferral.candidate_status === 'archived') &&
                  job.published && (
                    <PrimaryButton style={styles.applyButton} small onPress={handleApplyPress}>
                      Apply
                    </PrimaryButton>
                  )) ||
                  (job.published && (
                    <PrimaryButton style={styles.applyButton} small disabled>
                      Applied
                    </PrimaryButton>
                  ))}
                {firstReferral &&
                  firstReferral.candidate_status !== 'archived' &&
                  firstReferral.candidate_status !== 'expired' &&
                  job.published && <DeclineButton handleOnPress={handleOnPress} firstReferral={firstReferral} />}
              </View>
            )}
          </View>
        </View>
      </View>
      {showAllInfo && (
        <>
          <View style={[jobListElement.tagsWrapper, { paddingLeft: 0 }]}>
            {Boolean(job.work_type) && (
              <View style={jobListElement.tag}>
                <Text style={jobListElement.tagText}>{job.work_type}</Text>
              </View>
            )}
            {Boolean(job.job_experience_name) && (
              <View style={jobListElement.tag}>
                <Text style={jobListElement.tagText}>{job.job_experience_name}</Text>
              </View>
            )}
            {Boolean(job.work_place_type) && (
              <View style={jobListElement.tag}>
                <Text style={jobListElement.tagText}>{job.work_place_type}</Text>
              </View>
            )}
            {Boolean(job.job_urgency_type_name == 'Urgent') && (
              <View style={jobListElement.tag}>
                <Text style={{ ...jobListElement.tagText, display: 'flex' }}>
                  <TimerOutlinedIcon style={{ fontSize: 12, marginRight: 3 }} />
                  {job.job_urgency_type_name}
                </Text>
              </View>
            )}
          </View>
        </>
      )}
      {renderRewards}
      {direction !== 'my' && (
        <View style={[styles.secondaryButtons, styles.bottomButtons]} key="buttons">
          {firstReferral && firstReferral.candidate_status != 'applied' && (
            <PrimaryButton
              style={styles.secondaryButton}
              small
              onPress={() => resendReferral(firstReferral.request_id)}
            >
              Resend Referral
            </PrimaryButton>
          )}
        </View>
      )}
    </ComponentToRender>
  );
}
