import React, { Component } from "react";
import { View, TouchableOpacity, Image, Dimensions } from "react-native";
import Actions from "../../RouteActions";

//styles
import styles from "../../screens/GlobalSearch/styles";
import { listElement } from "../../generalStyles";

//components
import Text from "../Text/Text";
import ListElementButton from "../ListElementButton/ListElementButton";

//utils
import { locationObjToStr, normalizePrice } from "../../helperFunctions";

//images
import defaultJobAvatar from "../../assets/img/default_job_avatar.png";
import shareIcon from "../../assets/img/share_icon.png";
import { openReferFormDrawer } from "../../DrawerActions.js";
import { connect } from "react-redux";

class OrganizationJobListElement extends Component {
  actionButtons() {
    const referButtonStyle = {
      borderRadius: 3,
      height: 40,
      width: this.props.isMobile ? 60 : 100,
    };
    const buttonsWrapper = {
      paddingVertical: 8,
      paddingHorizontal: 4,
      flexDirection: "row",
    };
    const { job, employer } = this.props;
    const referInitialValues = job.id
      ? {
          job_id: {
            id: job.id,
            title: job.title,
          },
          organization_id: {
            id: employer.id,
            name: employer.name,
            logo_image_url: employer.logo_image_thumb,
          },
        }
      : {};
    return (
      <View
        style={[
          buttonsWrapper,
          { flexDirection: "row", justifyContent: "center" },
        ]}
      >
        <ListElementButton
          style={referButtonStyle}
          icon={shareIcon}
          onPress={() => this.props.openJobReferForm(referInitialValues)}
        >
          Refer
        </ListElementButton>
      </View>
    );
  }
  render() {
    const { job, onPress, isLast, showViewJob, refCreatedAt, isMobile } =
      this.props;
    const image = job.logo_image_url
      ? { uri: job.logo_image_url }
      : defaultJobAvatar;
    const orgJobStyle = {
      row: {
        flexDirection: "row",
        justifyContent: "space-between",
      },
      col: {
        paddingTop: 20,
      },
      bounty_amount: {
        paddingTop: 10,
        fontSize: 20,
        fontWeight: "bold",
        color: "black",
      },
      bounty_amountMobile: {
        paddingTop: 5,
        fontSize: 14,
        fontWeight: "bold",
        color: "black",
      },
    };
    return (
      <TouchableOpacity
        style={[listElement.elementWrapperTopAligned, orgJobStyle.row]}
        onPress={() => Actions.browseJobDetail({ id: job.id })}
      >
        <View>
          <View style={orgJobStyle.row}>
            <View style={listElement.imageWrapper}>
              <Image
                style={listElement.image}
                source={image}
                resizeMode="contain"
              />
            </View>
            <View
              style={[
                orgJobStyle.col,
                listElement.elementBody,
                listElement.elementWrapperWithMinHeight,
                isLast ? listElement.last : {},
              ]}
            >
              <View
                style={[
                  listElement.infoWrapper,
                  {
                    padding: 0,
                    width: isMobile
                      ? Math.round(Dimensions.get("window").width) / 2.8
                      : 230,
                  },
                ]}
              >
                <Text
                  style={[listElement.title, styles.jobTitle]}
                  numberOfLines={1}
                >
                  {job.title}
                </Text>
                {Boolean(job.location) && (
                  <Text style={listElement.description} numberOfLines={1}>
                    {locationObjToStr(job.location)}
                  </Text>
                )}
                <View style={listElement.tagsWrapper}>
                  {Boolean(job.work_type) && (
                    <View style={listElement.tag}>
                      <Text style={listElement.tagText}>
                        {job.work_type}
                      </Text>
                    </View>
                  )}
                  {job.work_place_type === "Yes" && (
                    <View style={listElement.tag}>
                      <Text style={listElement.tagText}>Remote</Text>
                    </View>
                  )}
                  {job.work_place_type === "Allowed" && (
                    <View style={listElement.tag}>
                      <Text style={listElement.tagText}>Remote allowed</Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={[
            orgJobStyle.col,
            isMobile && {
              width: Math.round(Dimensions.get("window").width) / 7,
            },
          ]}
        >
          {Boolean(job.bounty_amount) && !showViewJob && (
            <Text
              style={
                isMobile
                  ? orgJobStyle.bounty_amountMobile
                  : orgJobStyle.bounty_amount
              }
            >
              {job.bounty_amount}
            </Text>
          )}
        </View>
        <View style={{ paddingTop: 5, paddingRight: 20 }}>
          {this.actionButtons()}
        </View>
      </TouchableOpacity>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employer: state.organizationsReducer.organizationDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openJobReferForm: (data) => {
      dispatch(openReferFormDrawer(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationJobListElement);
