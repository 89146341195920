import React, { useMemo, useCallback, useEffect } from 'react';
import { FlatList, Image, Platform, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';

//images
import defaultOrgAvatar from '../../../../assets/img/default_org_avatar.png';
import defaultUserAvatar from '../../../../assets/img/default_user_avatar.png';
import likeGreen from '../../../../assets/img/like_green.png';
import likeGrey from '../../../../assets/img/like_grey.png';
import lineIconSVG from '../../../../assets/svg/line-icon.svg';
import peopleSVG from '../../../../assets/svg/people.svg';
import reviewReferralsIcon from '../../../../assets/img/review_referrals_icon.png';
import shareIcon from '../../../../assets/img/share_icon.png';
import referralPng from '../../../../assets/img/referralPng.png';
import { browseUrls } from '../../../../urls.js';
import { CURRENCIES } from '../../../../constants/currenciesConstants';
import winnerCupSvg from '../../../../assets/svg/winner-cup.svg';

//components
import ListElementButton from '../../../../components/ListElementButton/ListElementButton';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import Text from '../../../../components/Text/Text';
import { openReferFormDrawer } from '../../../../DrawerActions.js';

//utils
import { locationObjToStr, getDateTimeFromNow, formatDate } from '../../../../helperFunctions';
import respStyles from '../../../../responsiveStyles';
import Actions from '../../../../RouteActions';
import RecommendationsBanner from '../RecommendationsBanner/RecommendationsBanner';

//actions
import * as activityActions from './actions';

//styles
import styles from './styles';
import {
  common as commonStyles,
  mar15 as mar15,
  activityFlatListContainer,
  flatListContainer,
  jobListElement,
  listElement,
} from '../../../../generalStyles';

const ActivityTopSection = ({ data, currentUser }) => {
  const goEmployerProfile = () => {
    Actions.goEmployerProfile({
      employerProfileLink: data.organization.employer_profile_link,
    });
  };

  const renderContent = useMemo(() => {
    switch (data.action) {
      case 'bounty_amount_changed':
        return (
          <View style={[commonStyles.row, commonStyles.flex1]}>
            <TouchableOpacity onPress={goEmployerProfile}>
              <Text style={[styles.textBold, styles.topSectionText]}>{data.organization.name}</Text>
            </TouchableOpacity>
            <Text style={styles.topSectionText}>&nbsp;has updated the reward value for</Text>
          </View>
        );
      case 'closed':
        return (
          <View style={[commonStyles.row, commonStyles.flex1]}>
            <TouchableOpacity onPress={goEmployerProfile}>
              <Text style={[styles.textBold, styles.topSectionText]}>{data.organization.name}</Text>
            </TouchableOpacity>
            <Text style={styles.topSectionText}>&nbsp;has closed</Text>
            <Text style={[styles.textBold, styles.topSectionText]}>&nbsp;this job</Text>
          </View>
        );
      case 'archived':
        return (
          <View style={[commonStyles.row, commonStyles.flex1]}>
            <TouchableOpacity onPress={goEmployerProfile}>
              <Text style={[styles.textBold, styles.topSectionText]}>{data.organization.name}</Text>
            </TouchableOpacity>
            <Text style={styles.topSectionText}>&nbsp;has closed</Text>
            <Text style={[styles.textBold, styles.topSectionText]}>&nbsp;this job</Text>
          </View>
        );
      case 'published':
        return (
          <View style={[commonStyles.row, commonStyles.flex1]}>
            <TouchableOpacity onPress={goEmployerProfile}>
              <Text style={[styles.textBold, styles.topSectionText]}>{data.organization.name}</Text>
            </TouchableOpacity>
            <Text style={styles.topSectionText}>
              &nbsp;{data.activity_type === 'grouped_activity' ? 'have' : 'has'}
            </Text>
            <Text style={[styles.textBold, styles.topSectionText]}>
              &nbsp;{data.activity_type === 'grouped_activity' ? data.job.length : 1} new opening
            </Text>
          </View>
        );
      case 'referral_has_sent':
        return (
          <View style={[commonStyles.row, commonStyles.flex1]}>
            <View>
              <Text style={[styles.textBold, styles.topSectionText]}>
                {data.user && data.user.full_name === currentUser.full_name ? 'You' : data.user.full_name}
              </Text>
            </View>
            <Text style={styles.topSectionText}>&nbsp;sent</Text>
            <Text style={[styles.textBold, styles.topSectionText]}>&nbsp;a referral</Text>
          </View>
        );
      case 'refer_candidate_hired':
        return (
          <View style={[commonStyles.row, commonStyles.flex1]}>
            <Text>
              <TouchableOpacity onPress={goEmployerProfile}>
                <Text style={[styles.textBold, styles.topSectionText]}>{data.organization.name}</Text>
              </TouchableOpacity>
              <Text style={styles.topSectionText}>&nbsp;has hired someone for</Text>
              <Text style={[styles.textBold, styles.topSectionText]}>&nbsp;this job</Text>
              <Text style={listElement.description}>&nbsp;you provided a referral</Text>
            </Text>
          </View>
        );
      case 'unpublished':
        return (
          <View style={[commonStyles.row, commonStyles.flex1]}>
            <TouchableOpacity onPress={goEmployerProfile}>
              <Text style={[styles.textBold, styles.topSectionText]}>{data.organization.name}</Text>
            </TouchableOpacity>
            <Text style={styles.topSectionText}>&nbsp;has closed</Text>
            <Text style={[styles.textBold, styles.topSectionText]}>&nbsp;this job</Text>
          </View>
        );
      case 'urgency_level_changed':
        return (
          <View style={[commonStyles.row, commonStyles.flex1]}>
            <TouchableOpacity onPress={goEmployerProfile}>
              <Text style={[styles.textBold, styles.topSectionText]}>{data.organization.name}</Text>
            </TouchableOpacity>
            <Text style={styles.topSectionText}>&nbsp;has changed the urgency level of</Text>
            <Text style={[styles.textBold, styles.topSectionText]}>&nbsp;the job</Text>
          </View>
        );
    }
    return null;
  }, [data]);

  return (
    <View style={[commonStyles.row, commonStyles.alignItemsCenter, styles.topSection]}>
      {renderContent}
      <View>
        <Text style={[styles.timestamp]}>{getDateTimeFromNow(data.date)}</Text>
      </View>
    </View>
  );
};

const candidate_hired = ({ data }) => {
  const userAvatar = data.user.avatar_thumb ? { uri: data.user.avatar_thumb } : defaultUserAvatar;
  const organizationLogo =
    data.organization && data.organization.logo_image_thumb
      ? { uri: data.organization.logo_image_thumb }
      : defaultOrgAvatar;
  return (
    <View style={styles.listElement}>
      <TouchableOpacity
        style={listElement.elementWrapper}
        onPress={() => Actions.contactProfile({ userId: data.user.id })}
      >
        <View style={[listElement.elementBody, styles.elementBody, styles.elementBodyMoved]}>
          <View style={[listElement.infoWrapper, styles.infoWrapperMoved]}>
            <Text style={listElement.title}>{data.user.full_name}</Text>
            <Text style={listElement.description}>Has been hired by {data.organization.name}</Text>
          </View>
          <Text style={[listElement.timestamp, styles.timestamp]}>{getDateTimeFromNow(data.date)}</Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity style={listElement.elementWrapper} onPress={() => Actions.browseJobDetail({ id: data.job.id })}>
        <View style={[listElement.imageWrapper, styles.imageWrapper]}>
          <Image style={[listElement.image, listElement.imageRounded]} source={userAvatar} resizeMode="contain" />
          <Image style={listElement.subSmallImage} source={organizationLogo} resizeMode="contain" />
        </View>
        <View style={[listElement.elementBody, styles.elementBody, styles.bottomElementBody]}>
          <View style={listElement.infoWrapper}>
            <Text style={[listElement.title, styles.jobTitle]} numberOfLines={1}>
              {data.job.title}
            </Text>
            {Boolean(data.job.location) && (
              <Text style={listElement.description}>{locationObjToStr(data.job.location)}</Text>
            )}
            <View style={listElement.tagsWrapper}>
              {Boolean(data.job.work_type) && (
                <View style={listElement.tag}>
                  <Text style={listElement.tagText}>{data.job.work_type}</Text>
                </View>
              )}
              {data.job.work_place_type === 'Yes' && (
                <View style={listElement.tag}>
                  <Text style={listElement.tagText}>Remote</Text>
                </View>
              )}
              {data.job.work_place_type === 'Allowed' && (
                <View style={listElement.tag}>
                  <Text style={listElement.tagText}>Remote allowed</Text>
                </View>
              )}
            </View>
          </View>
          <View style={listElement.rightBlock}>
            {Boolean(data.metadata && data.metadata.bounty_amount) && (
              <Text style={listElement.salary}>{data.metadata.bounty_amount}</Text>
            )}
            <Text style={listElement.timestamp}>{formatDate(data.job.published_at)}</Text>
          </View>
        </View>
      </TouchableOpacity>
      <ActionButtons data={item} />
    </View>
  );
};

const Recommendations = ({ data, hideRecommendations }) => {
  return <RecommendationsBanner data={data.data} hideRecommendations={hideRecommendations} />;
};

const ReferActionButtons = ({ data, onReferClick }) => {
  const isClosed = ['closed', 'unpublished'].includes(data.action);
  const referInitialValues =
    data.job && data.job.id && data.organization
      ? {
          job_id: {
            id: data.job.id,
            title: data.job.title,
          },
          organization_id: {
            id: data.organization.id,
            name: data.organization.name,
            logo_image_url: data.organization.logo_image_thumb,
          },
        }
      : {};

  return (
    <View style={styles.buttonsWrapper}>
      {data.job && data.job.active && !isClosed && (
        <ListElementButton
          style={styles.referButtonStyle}
          icon={referralPng}
          textStyle={styles.buttonTextStyle}
          customIconSize={styles.customIconSize}
          onPress={() => onReferClick(referInitialValues)}
        ></ListElementButton>
      )}
    </View>
  );
};

const ActionButtons = ({ data, onLikeActivity, onUnlikeActivity, onReferClick }) => {
  const isClosed = ['closed', 'unpublished'].includes(data.action);
  const referInitialValues =
    data.job && data.job.id && data.organization
      ? {
          job_id: {
            id: data.job.id,
            title: data.job.title,
          },
          organization_id: {
            id: data.organization.id,
            name: data.organization.name,
            logo_image_url: data.organization.logo_image_thumb,
          },
        }
      : {};

  return (
    <View style={styles.buttonsWrapper}>
      {data.liked ? (
        <ListElementButton style={styles.buttonStyle} icon={likeGreen} onPress={() => onUnlikeActivity(data.id, data)}>
          Like
        </ListElementButton>
      ) : (
        <ListElementButton
          style={styles.buttonStyle}
          secondary
          icon={likeGrey}
          onPress={() => onLikeActivity(data.id, data)}
        >
          Like
        </ListElementButton>
      )}
      {data.job && data.job.active && !isClosed && (
        <ListElementButton
          style={styles.buttonStyle}
          icon={shareIcon}
          textStyle={styles.buttonTextStyle}
          onPress={() => onReferClick(referInitialValues)}
        >
          Refer
        </ListElementButton>
      )}
    </View>
  );
};

const ReferButtonForRecommendations = ({ data, onReferClick }) => {
  const referInitialValues =
    data.job && data.job.id && data.organization
      ? {
          name: data.full_name,
          email: data.email,
          job_id: {
            id: data.job.id,
            title: data.job.title,
          },
          organization_id: {
            id: data.organization.id,
            name: data.organization.name,
            logo_image_url: data.organization.logo_image_thumb,
          },
        }
      : {};

  return (
    <View style={styles.buttonsWrapper}>
      <ListElementButton
        style={styles.buttonStyle}
        icon={shareIcon}
        textStyle={styles.buttonTextStyle}
        onPress={() => onReferClick(referInitialValues)}
      >
        Refer
      </ListElementButton>
    </View>
  );
};

const RecommendationItem = ({ data, onReferClick }) => {
  const userAvatar = data.data.avatar_thumb ? { uri: data.data.avatar_thumb } : defaultUserAvatar;
  const wrapperStyles = [listElement.elementWrapper, styles.elementWrapper];
  const avatarStyles = [listElement.image, listElement.imageRounded];

  return (
    <TouchableOpacity style={wrapperStyles} onPress={() => Actions.contactProfile({ userId: data.data.id })}>
      <View style={[listElement.imageWrapper, styles.imageWrapper]}>
        <Image style={avatarStyles} source={userAvatar} resizeMode="contain" />
      </View>
      <View style={[listElement.elementBody]}>
        <View style={[styles.wrapperStylesForList]}>
          <Text style={listElement.title}>{data.data.full_name}</Text>
          <Text style={listElement.description}>{data.data.company_position}</Text>
          <Text style={listElement.description}>{data.data.city_state}</Text>
        </View>
        <ReferButtonForRecommendations data={data.item} onReferClick={onReferClick} />
      </View>
    </TouchableOpacity>
  );
};

const UserAction = ({ data, text }) => {
  const userAvatar = data.user.avatar_thumb ? { uri: data.user.avatar_thumb } : defaultUserAvatar;
  const organizationLogo =
    data.organization && data.organization.logo_image_thumb
      ? { uri: data.organization.logo_image_thumb }
      : defaultOrgAvatar;
  return (
    <View style={styles.listElement}>
      <TouchableOpacity
        style={listElement.elementWrapper}
        onPress={() => Actions.contactProfile({ userId: data.user.id })}
      >
        <View style={[listElement.imageWrapper, styles.imageWrapper]}>
          <Image style={[listElement.image, listElement.imageRounded]} source={userAvatar} resizeMode="contain" />
        </View>
        <View style={[listElement.elementBody, styles.elementBody]}>
          <View style={listElement.infoWrapper}>
            <Text style={listElement.title}>{data.user.full_name}</Text>
            <Text style={listElement.description}>{text}</Text>
          </View>
          <Text style={[styles.timestamp]}>{getDateTimeFromNow(data.date)}</Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        style={listElement.elementWrapper}
        onPress={() =>
          Actions.browseJobDetail({
            id: data.job.id,
          })
        }
      >
        <View style={[listElement.imageWrapper, styles.imageWrapper]}>
          <Image style={listElement.image} source={organizationLogo} resizeMode="contain" />
        </View>
        <View style={[listElement.elementBody, styles.elementBody, styles.bottomElementBody]}>
          <View style={listElement.infoWrapper}>
            <Text style={listElement.title} numberOfLines={1}>
              {data.organization.name}
            </Text>
            <Text style={listElement.description}>{data.job.title}</Text>
            {Boolean(data.job.location) && (
              <Text style={listElement.description}>{locationObjToStr(data.job.location)}</Text>
            )}
          </View>
          <View style={listElement.rightBlock}>
            {Boolean(data.job.work_type) && (
              <View style={listElement.tag}>
                <Text style={listElement.tagText}>{data.job.work_type}</Text>
              </View>
            )}
            {data.job.work_place_type === 'Yes' && (
              <View style={listElement.tag}>
                <Text style={listElement.tagText}>Remote</Text>
              </View>
            )}
            {data.job.work_place_type === 'Allowed' && (
              <View style={listElement.tag}>
                <Text style={listElement.tagText}>Remote allowed</Text>
              </View>
            )}
          </View>
        </View>
      </TouchableOpacity>
      <View style={jobListElement.hr}></View>
      <ActionButtons data={item} />
    </View>
  );
};

const OrganizationAction = ({ data, onLikeActivity, onUnlikeActivity, onReferClick, currentUser, isGroupActivity }) => {
  if (!data.organization) return null;
  const organizationLogo = data.organization.logo_image_thumb
    ? { uri: data.organization.logo_image_thumb }
    : defaultOrgAvatar;
  const isClosed = ['closed', 'unpublished'].includes(data.action);
  const isArchived = data.action === 'archived';

  const renderRewards = useMemo(() => {
    if (!data.job || !data.job.reward_templates) return null;

    const { currency_id = 'USD', experiential_reward = '', monetary_reward = 0 } = data.job.reward_templates;

    if ((experiential_reward.length || monetary_reward) > 0) {
      return (
        <View>
          <View
            style={[
              commonStyles.row,
              commonStyles.alignItemsCenter,
              commonStyles.justifyContentEnd,
              { marginBottom: 4 },
            ]}
          >
            <Text
              style={{
                color: '#808080',
                fontWeight: '700',
                fontSize: 12,
                marginRight: 8,
              }}
            >
              REFERRAL REWARDS
            </Text>
            <Image style={jobListElement.winnerIcon} source={winnerCupSvg} resizeMode="contain" />
          </View>
          <View style={[commonStyles.row, commonStyles.justifyContentEnd]}>
            {Boolean(experiential_reward) && (
              <View style={jobListElement.referralReward}>
                <Text style={[jobListElement.referralRewardText, commonStyles.textEllipsis]}>
                  {experiential_reward.length > 22
                    ? `${experiential_reward.substring(0, 22)} ....`
                    : experiential_reward}
                </Text>
              </View>
            )}
            <View style={[jobListElement.referralReward, jobListElement.referralRewardPrice]}>
              <Text style={jobListElement.referralRewardText}>
                {CURRENCIES[currency_id]}
                {monetary_reward}
              </Text>
            </View>
          </View>
        </View>
      );
    }

    return null;
  }, [data.job]);

  return (
    <View
      style={[
        isGroupActivity ? styles.groupListElement : styles.listElement,
        isGroupActivity ? styles.groupListElement : null,
      ]}
    >
      {isGroupActivity ? null : <ActivityTopSection data={data} currentUser={currentUser} />}
      {isGroupActivity ? null : <View style={jobListElement.hr}></View>}
      <TouchableOpacity
        style={isGroupActivity ? styles.groupActivityContent : styles.activityContent}
        onPress={() => {
          if (!isClosed) {
            if (data.job.category === 'job') {
              Actions.browseAllJobDetail({
                id: data.job.uuid,
              });
            } else {
              Actions.browseAllDepartmentDetail({
                id: data.job.uuid,
              });
            }
          }
        }}
      >
        {isGroupActivity ? null : isClosed ? (
          <Image
            style={[
              listElement.imageTransparent,
              {
                width: 52,
                height: 52,
                borderRadius: 5,
                borderColor: '#CCCCCC',
                borderWidth: 1,
                borderStyle: 'solid',
                overflow: 'hidden',
              },
            ]}
            source={organizationLogo}
            resizeMode="contain"
          />
        ) : (
          <Image
            style={{
              width: 52,
              height: 52,
              borderRadius: 5,
              borderColor: '#CCCCCC',
              borderWidth: 1,
              borderStyle: 'solid',
            }}
            source={organizationLogo}
            resizeMode="contain"
          />
        )}

        <View style={{ paddingLeft: isGroupActivity ? 0 : 10, paddingRight: 0, flex: 1 }}>
          <Text style={{ fontSize: 16, fontWeight: '600' }} numberOfLines={1}>
            {data.job.title}
          </Text>
          {Boolean(data.organization) && (
            <TouchableOpacity
              style={commonStyles.linkWrapper}
              onPress={() =>
                Actions.goEmployerProfile({
                  employerProfileLink: data.organization.employer_profile_link,
                })
              }
            >
              <Text style={{ fontSize: 12, opacity: 0.6, marginTop: 2 }}>{data.organization.name}</Text>
            </TouchableOpacity>
          )}
          {Boolean(data.job.location) && (
            <View style={commonStyles.linkWrapper}>
              <Text style={{ color: '#959595', fontSize: 12, marginTop: 2 }}>
                {locationObjToStr(data.job.location)}
              </Text>
            </View>
          )}
        </View>

        {renderRewards}
        {isGroupActivity ? <ReferActionButtons data={data} onReferClick={onReferClick} /> : null}
      </TouchableOpacity>

      {Boolean(data.matching_users.length != 0) && (
        <View style={[styles.screenWrapper, styles.paddingLeftFiftyRightTwenty]}>
          <View style={[styles.sectionBreaker]} />

          <FlatList
            style={Platform.OS === 'web' && flatListContainer}
            data={data.matching_users}
            renderItem={(rItem) => <RecommendationItem data={rItem} onReferClick={onReferClick} />}
            keyExtractor={(data, index) => `${data}_${index}`}
          />
        </View>
      )}

      <View style={jobListElement.hr}></View>
      {isGroupActivity ? null : (
        <ActionButtons
          data={data}
          onLikeActivity={onLikeActivity}
          onUnlikeActivity={onUnlikeActivity}
          onReferClick={onReferClick}
        />
      )}
    </View>
  );
};

const OrganizationMultiAction = ({
  data,
  onLikeActivity,
  onUnlikeActivity,
  onReferClick,
  currentUser,
  childrenActivity,
}) => {
  const currentObject = { ...data, job: data.job[0], liked: data.liked, likes: data.likes };

  if (!data.organization) return null;
  const organizationLogo = data.organization.logo_image_thumb
    ? { uri: data.organization.logo_image_thumb }
    : defaultOrgAvatar;

  const isClosed = ['closed', 'unpublished'].includes(data.action);

  let updateObject = childrenActivity.map((el) => ({
    ...el,
    job: el,
    organization: data.organization,
    matching_users: data.matching_users,
  }));
  updateObject = updateObject.length > 3 ? updateObject.slice(0, 3) : updateObject;

  const goToBrowsePage = useCallback(
    () =>
      Actions.goEmployerProfile({
        employerProfileLink: data.organization.employer_profile_link,
      }),
    []
  );

  return (
    <View style={styles.listElement}>
      <ActivityTopSection data={data} currentUser={currentUser} />
      <View style={jobListElement.hr}></View>
      <TouchableOpacity
        style={styles.activityContent}
        onPress={() => {
          if (!isClosed) {
            if (currentObject.job.category === 'job') {
              Actions.browseAllJobDetail({
                id: currentObject.job.uuid,
              });
            } else {
              Actions.browseAllDepartmentDetail({
                id: currentObject.job.uuid,
              });
            }
          }
        }}
      >
        {isClosed ? (
          <Image
            style={[listElement.imageTransparent, styles.orgImageTransparent]}
            source={organizationLogo}
            resizeMode="contain"
          />
        ) : (
          <Image style={[styles.orgLogoTransparent]} source={organizationLogo} resizeMode="contain" />
        )}
        <View style={[styles.activityContainer]}>
          <View style={[styles.jobTitleContainer]}>
            <Text style={styles.jobTitleText} numberOfLines={1}>
              {currentObject.organization.name}
            </Text>
            {Boolean(data.organization) && (
              <TouchableOpacity style={commonStyles.linkWrapper} onPress={goToBrowsePage}>
                <Text style={[styles.jobOrgName]}>{data.name}</Text>
              </TouchableOpacity>
            )}
            {Boolean(currentObject.job.location) && (
              <TouchableOpacity
                style={commonStyles.linkWrapper}
                onPress={() =>
                  Actions.goEmployerProfile({
                    employerProfileLink: data.organization.employer_profile_link,
                  })
                }
              >
                <Text style={[styles.jobLocationStyling]}>
                  {locationObjToStr(currentObject.organization.organization_location)}
                </Text>
              </TouchableOpacity>
            )}
          </View>
          <View>
            <Text style={styles.jobType}>{currentObject.job.title}</Text>
            <Text style={styles.jobNumber}>+{data.job.length - 1} more</Text>
          </View>
        </View>
      </TouchableOpacity>
      <View style={jobListElement.hr}></View>

      <ActionButtons
        data={currentObject}
        onLikeActivity={onLikeActivity}
        onUnlikeActivity={onUnlikeActivity}
        onReferClick={onReferClick}
      />
      <View style={jobListElement.hr}></View>

      {updateObject.map((el) => (
        <OrganizationAction
          data={el}
          onLikeActivity={onLikeActivity}
          onUnlikeActivity={onUnlikeActivity}
          onReferClick={onReferClick}
          currentUser={currentUser}
          isGroupActivity={true}
        />
      ))}
      {childrenActivity.length > 3 ? (
        <TouchableOpacity
          style={styles.showMore}
          onPress={() =>
            Actions.goEmployerProfile({
              employerProfileLink: browseUrls.browseAll,
            })
          }
        >
          <Text style={styles.showMore}>See more</Text>
        </TouchableOpacity>
      ) : null}
    </View>
  );
};
const UnreviewedReferrals = () => {
  return (
    <TouchableOpacity style={styles.listElement} onPress={() => Actions.tracker({ direction: 'my', index: 0 })}>
      <View style={styles.permissionWrapper}>
        <Image source={reviewReferralsIcon} style={styles.permissionIcon} resizeMode="contain" />
        <View style={{ flex: 1 }}>
          <Text style={styles.permissionTitle}>Review your referrals</Text>
          <Text style={styles.permissionDescription}>
            You’ve have new referrals pending. {`\n`}Take action before they expire!
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const Activity = (props) => {
  const {
    activityList,
    activityListLoading,
    selectedOrganization,
    isLastActivityPage,
    newRefersCount,
    withoutTabs,
    getActivityList,
    onLikeActivity,
    onUnlikeActivity,
    hideRecommendations,
    onReferClick,
    currentUser,
    userOrganizationsLoading,
  } = props;
  const web = Platform.OS === 'web';

  const loadMore = () => {
    if (!isLastActivityPage && !activityListLoading) {
      getActivityList({
        organization_id: selectedOrganization.id,
        offset: activityList.length,
      });
    }
  };

  useEffect(() => {
    getActivityList({
      organization_id: selectedOrganization && selectedOrganization.id,
    });
  }, [selectedOrganization]);

  return (
    <View style={styles.container}>
      <FlatList
        onRefresh={() => getActivityList({ organization_id: selectedOrganization.id })}
        refreshing={activityListLoading}
        style={[styles.screenWrapper, web && !withoutTabs && activityFlatListContainer]}
        contentContainerStyle={styles.listContainerPadingTop}
        data={activityList}
        renderItem={({ item }) => {
          if ((item.job && item.job.active) || (item.job && item.job[0] && item.job[0].active)) {
            if (item.activity_type == 'single_activity') {
              return (
                <OrganizationAction
                  data={item}
                  onLikeActivity={onLikeActivity}
                  onUnlikeActivity={onUnlikeActivity}
                  onReferClick={onReferClick}
                  currentUser={currentUser}
                />
              );
            } else if (item.activity_type == 'grouped_activity') {
              return (
                <OrganizationMultiAction
                  data={item}
                  childrenActivity={item.job}
                  onLikeActivity={onLikeActivity}
                  onUnlikeActivity={onUnlikeActivity}
                  onReferClick={onReferClick}
                  currentUser={currentUser}
                />
              );
            } else {
              return null;
            }
          } else {
            return null;
          }
        }}
        keyExtractor={(item, index) => `${item}_${index}`}
        onEndReached={(e) => {
          if (!withoutTabs) loadMore();
        }}
        ListHeaderComponent={Boolean(newRefersCount) && <UnreviewedReferrals />}
      />
      {withoutTabs && !isLastActivityPage && !activityListLoading && (
        <View style={[{ width: '100%', marginBottom: 10 }, respStyles.flexCenter]}>
          <PrimaryButton style={styles.loadMoreButton} small onPress={loadMore}>
            Load more
          </PrimaryButton>
        </View>
      )}
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    activityList: state.activityReducer.activityList,
    activityListLoading: state.activityReducer.activityListLoading,
    selectedOrganization: state.authReducer.currentSelectedOrg,
    isLastActivityPage: state.activityReducer.isLastActivityPage,
    newRefersCount: state.trackerReducer.newRefersCount,
    currentUser: state.authReducer.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActivityList: (params) => {
      dispatch(activityActions.getActivityList(params));
    },
    onLikeActivity: (activityId, data) => {
      dispatch(activityActions.likeActivity(activityId, data));
    },
    onUnlikeActivity: (activityId) => {
      dispatch(activityActions.unlikeActivity(activityId));
    },
    hideRecommendations: () => {
      dispatch(activityActions.hideRecommendations());
    },
    onReferClick: (initialValues) => {
      dispatch(openReferFormDrawer(initialValues));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
